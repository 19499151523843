<script setup>
    import Leftbar from './Leftbar.vue';
    import Header from './Header.vue';

    const props = defineProps(['user']);
    window.userStore.setUser(props.user);
</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
        </main>
    </div>
</template>
