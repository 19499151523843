export const Defaults = {
    // Copy to clipboard
    copy: (text, tooltip = true) => {
        const storage = document.createElement('textarea');
        storage.value = text;
        document.body.appendChild(storage);

        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(storage);

        if(tooltip){
            let timeout = null;

            let alertDiv = document.createElement('div');
            alertDiv.classList.add('alert');
            alertDiv.classList.add('alert_success');

            let closeSelector = document.createElement('div');
            closeSelector.classList.add('alert__close');
            alertDiv.appendChild(closeSelector);

            let textSelector = document.createElement('div');
            textSelector.innerHTML = `${window._TRANSLATION.color_code.successfully_copied}: ${text}`;
            alertDiv.appendChild(textSelector);

            let progressLineSelector = document.createElement('div');
            progressLineSelector.classList.add('alert__progress');
            alertDiv.appendChild(progressLineSelector);

            if( document.body.querySelector('.alert') != null) {
                document.body.querySelector('.alert').remove();
                clearTimeout(timeout);
            }

            document.body.appendChild(alertDiv);

            setInterval( () => {
                progressLineSelector.style.width = `${progressLineSelector.offsetWidth - 2}px`;
            }, 10);

            document.querySelector('.alert__close').addEventListener('click', () => {
                alertDiv.remove();
            });

            timeout = setTimeout(()=> {
                alertDiv.remove();
            }, 2200);

        }
    },
    /* Resize block */
    resize: (selector, extraOffsetHeight = 50) => {
        if(window.innerWidth > 768){
            document.querySelector(selector).style.height = `${document.body.offsetHeight + extraOffsetHeight}px`;
        } else {
            document.querySelector(selector).style.height = 'auto';
        }
    }
}
